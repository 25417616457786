:root {
  --base-color: #4A90E2;
  --base-bk-color: #F1F2F7;
  --base-text-color: #787878;

  --secondary-color: #F5A623;

  --warning-color: #f5a623;
  --danger-color: #ff001f;

  --base-color-hover: #779CCA;
  --base-dark-color-hover: #3B73B4;
  --hover-mask: rgba(white, 0.1);

  --light-text-color: #dbdbdb;
  --light-color: #DFE7F4;
  --light-border-color: #EBEBEB;

  --dark-text-color: #4A4A4A;
  --dark-color: #33649D;
  --dark-base-color: #2E5E95;
  --dark-secondary-color: rgb(195,131,27);
  --dark-black-color: #464646;
  --dark-transparency-color: rgba(74,74,74,.1);
  --input-transparency-color: rgba(0,0,0,.05);

  --nav-bk-color: #4E4E4E;

  // Section colors
  --section-color: #DFE7F4;
  --section-border-color: #D1D9E5;
  --section-danger-color: #F2D9E0;
  --section-danger-handle-color: #FF001F;
  --section-danger-handle-border-color: #B20015;
  --section-warning-color: #fde8c8;
  --section-warning-handle-color: #f9b42c;
  --section-warning-handle-border-color: #ba861c;

  //Session colors
  --session-left-view-color: #F2F2F2;
  --session-right-view-color: #E5E5E5;

  // Notification colors
  --notify-error-color: #FF001F;

  --lock-border-color: #c1c2c6;

  --border-radius: .5rem;

  // New Variables
  --font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --left-panel-width: 18.5rem;
  --right-thread-width: 25.5rem;
  --left-menu-animation: .5s;
  --right-thread-animation: .4s;
  --alg-success-color: #9acc68;
  --alg-failure-color: #910112;

  // Re-Design colors
  --alg-primary-color: #0F61FF;
  --alg-primary-light-color: #dfe6f7;
  --alg-primary-dark-color: #012873;
  --alg-primary-dark-hover-color: #003da8;
  --alg-secondary-color: #616F82;
  --alg-accent-color: #fef0e6;
  --alg-accent-dark-color: #FF6600;
  --alg-black-color: #1D2227;
  --alg-white-color: #fff;
  --alg-light-color: #EEEEEE;
  --alg-border-color: #DEDEDE;
  --alg-border-light-color: rgba(0, 0, 0, .1);
  --alg-light-bg-color: #e8efff;
  --alg-tree-line-color: #d7d7d7;
  --alg-tree-selected-color: #d4e1ff;
  --alg-loading-bg: #d2dfff;
  --alg-light-text-color: #000;
  --alg-dark-text-color: #fff;
  --alg-children-item-hover-color: #f7f8f9;
  --alg-switch-control-caption-color: #545454;
  --alg-switch-input-bg: #e0e2e6;
  --alg-search-result-highlight: #0F61FF33;
  --alg-observation-button-bg-color: #ffdfcb;
  --alg-form-item-label: #545454;

  // Spacers
  --alg-space-size-1: 4rem;
  --alg-space-size-2: 3rem;
  --alg-space-size-3: 2rem;
  --alg-space-size-4: 1.5rem;
  --alg-space-size-5: 1rem;
  --alg-space-size-6: .5rem;
}

