@import 'src/assets/scss/functions';

.alg-button {
  border: none;
  text-decoration: none;

  &:focus, &:active {
    outline: none;
    box-shadow: none;
  }

  &.basic {
    &.p-button {
      &:not([icon]) {
        padding: 0 toRem(15);
        border-radius: toRem(20);
      }

      &[icon] {
        padding: 0 toRem(7) 0 toRem(15);
        border-radius: toRem(12);
      }

      flex-direction: row-reverse;
      color: var(--alg-secondary-color);
      font-size: toRem(12);
      border: toRem(1) solid var(--alg-light-color);
      background: none;
      height: toRem(24);
    }

    .p-button-icon {
      margin-left: toRem(7);
      color: var(--alg-primary-color);
      font-size: toRem(16);
    }

    &:hover {
      background: var(--alg-light-color);
    }
  }

  &.primary {
    &.p-button {
      padding: 0 toRem(18);
      flex-direction: row-reverse;
      color: var(--alg-white-color);
      font-size: toRem(16);
      text-decoration: none;
      border-radius: toRem(20);
      background: var(--alg-primary-color);
      height: toRem(40);
    }

    .p-button-icon {
      margin-left: toRem(7);
      color: var(--alg-white-color);
      font-size: toRem(16);
    }
  }

  &.danger {
    &.p-button {
      padding: 0 toRem(18);
      flex-direction: row-reverse;
      color: var(--alg-white-color);
      font-size: toRem(16);
      text-decoration: none;
      border-radius: toRem(20);
      background: var(--danger-color);
      height: toRem(40);
    }

    .p-button-icon {
      margin-left: toRem(7);
      color: var(--alg-white-color);
      font-size: toRem(16);
    }
  }

  &.small {
    &.p-button {
      height: toRem(32);
      font-size: toRem(12);
      font-weight: 500;
    }
  }

  &.mini {
    &.p-button {
      padding: toRem(8);
      height: toRem(20);
      font-size: toRem(12);
      font-weight: 500;
    }
  }

  &.light-border {
    border: toRem(1) solid var(--alg-white-color);

    &:hover {
      border: toRem(1) solid var(--alg-white-color) !important;
    }
  }

  &.left-menu {
    width: 100%;

    &.p-button {
      padding-left: toRem(7.5);
      padding-right: toRem(7.5);
      flex-direction: row;
      border-radius: toRem(10);
      background-color: transparent;
      color: rgb(84, 84, 84);
      font-weight: 700;
    }

    .p-button-icon {
      margin-right: toRem(7.5);
      font-size: toRem(24);
      color: var(--alg-primary-color);
    }

    &.left-menu-selected {
      &.p-button {
        background-color: var(--alg-tree-selected-color);
      }
    }
  }

  &.only-caption {
    &.p-button {
      padding: 0;
      color: var(--alg-secondary-color);
      background: none;
    }

    &.edit-permissions {
      font-size: toRem(12);
      color: var(--alg-accent-dark-color);
    }
  }
}
