@import "src/assets/scss/functions";

.alg-top-right-menu {
  &.p-menu-overlay {
    background-color: var(--alg-primary-color);
    border-radius: toRem(10);
    border: none;
    overflow: hidden;
    box-shadow: 0 toRem(6) toRem(10) 0 rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0 toRem(6) toRem(10) 0 rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 toRem(6) toRem(10) 0 rgba(0, 0, 0, 0.2);
  }

  &.p-menu {
    width: auto;

    .p-menuitem {
      &:not(:last-child) {
        border-bottom: toRem(1) solid var(--alg-border-light-color);
      }
    }

    .p-menuitem-content {
      background: none !important;
    }

    .p-menuitem-link {
      margin: var(--alg-space-size-6);
      flex-direction: row-reverse;
      border-radius: toRem(10);

      &:active, &:focus {
        outline: none;
        box-shadow: none;
      }

      .p-menuitem-text, .p-menuitem-icon {
        color: var(--alg-white-color) !important;
        font-weight: 400;
      }

      .p-menuitem-text {
        font-size: toRem(12);
      }

      .p-menuitem-icon {
        margin: 0 0 0 var(--alg-space-size-6);
        font-size: toRem(16);
      }

      &:not(.p-disabled):hover {
        background-color: var(--alg-primary-dark-color);

        .p-menuitem-text, .p-menuitem-icon {
          color: white;
        }
      }
    }
  }

  .p-submenu-header {
    height: toRem(40);
    background-color: var(--alg-primary-dark-color);
    color: white;
    font-size: toRem(12);
    font-weight: 500;
    text-align: left;
  }

  &.for-mobile {
    &.p-menu {
      .p-menuitem-link {
        margin: 0;
        padding-top: 0;
        padding-bottom: 0;
        height: toRem(40);
        border-radius: 0;
        justify-content: space-between;
      }
    }
  }
}
