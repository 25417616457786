/* You can add global styles to this file, and also import other style files */
@import 'src/variables';
@import 'src/geometry';
@import 'assets/scss/helpers';
@import 'assets/scss/components/page-header';
@import 'assets/scss/components/table';
@import 'assets/scss/components/editable-table';
@import 'assets/scss/components/error-message';
@import 'assets/scss/components/data-list-container';
@import 'assets/scss/components/mode-bar';
@import 'assets/scss/components/select-dropdown';
@import 'assets/scss/components/calendar';
@import 'assets/scss/components/tab-rounded';
@import 'assets/scss/components/tab-nav';
@import 'assets/scss/components/tree-nav';
@import 'assets/scss/components/login-button';
@import 'assets/scss/components/dropdown';
@import 'assets/scss/components/switch';
@import 'assets/scss/components/top-right-menu';
@import 'assets/scss/components/top-right-menu-button';
@import 'assets/scss/components/button';
@import 'assets/scss/components/button-icon';
@import 'assets/scss/components/messages';
@import 'assets/scss/components/permissions-edit-dialog';
@import 'assets/scss/components/propagation-edit-menu';
@import 'assets/scss/components/group-join-by-code';
@import 'assets/scss/components/block-ui';
@import 'assets/scss/components/tooltip';
@import 'assets/scss/components/path-suggestion-overlay';
@import 'assets/scss/components/suggestion-of-activities-overlay';
@import 'assets/scss/components/rounded-icon-wrapper';
@import 'assets/scss/components/input';
@import 'assets/scss/components/top-bar-scrollbar';

// New Design
@import 'assets/fonts/roboto/stylesheet';
@import 'assets/scss/text-colors';
@import 'assets/scss/text-sizes';

/* Custom themes */
@import 'assets/scss/themes/coursera-pt';

@media screen and (max-width: 599.98px) {
  :root {
    --alg-space-size-1: 2rem;
    --alg-space-size-2: 1.5rem;
    --alg-space-size-3: 1rem;
    --alg-space-size-4: .75rem;
    --alg-space-size-5: .5rem;
  }
}

* {
  box-sizing: content-box;
}

.p-component, .p-component * {
  box-sizing: border-box !important;
}

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

html {
  font-size: 16px;
}

body {
  background-color: var(--alg-light-bg-color);
  font-family: var(--font-family);
}

.cdk-global-overlay-wrapper {
  background-color: #FFFC;
}

.cdk-overlay-connected-position-bounding-box {
  margin-bottom: -2rem;
}

.user-progress-overlay-panel {
  background-color: transparent;
  box-shadow: none;
  min-height: unset !important;
  border: none;

  &:before {
    border-bottom-color: #295485;
    border-width: 0.4167rem;
  }

  &:after {
    border: none;
  }

  &.p-overlaypanel-flipped {
    &:before {
      border-top-color: var(--base-color);
    }

    &:after {
      border: none;
    }
  }

  .p-overlaypanel-content {
    padding: 0;
  }
}

.p-tooltip.tooltip-custom {

  .p-tooltip-text {
    background-color: var(--base-color);
    border-radius: 1rem;
    padding: 1rem 2rem;
  }

  &.p-tooltip-top {
    .p-tooltip-arrow {
      border-top-color: var(--base-color);
    }
  }

}

.common-confirm-popup {
  margin-left: 2rem;
  margin-right: 2rem;
}

.html-container {
  a {
    @extend .alg-link;
    @extend .base-color;
  }
}

.grid-card-image-default-bg {
  background-image: url("//assets.algorea.org/deployments/opentezos-89/en/images/chapter-default.jpg");
}

.alg-code-wrapper {
  margin: .3rem 0;
  padding: .2rem .4rem;
  max-width: 100%;
  border: .1rem solid rgba(0, 0, 0, .05);
  border-radius: .5rem;
  background-color: rgba(0, 0, 0, .05);
  text-align: left;
  overflow: scroll;

  .alg-code {
    white-space: unset;
  }
}

.alg-code {
  color: var(--alg-white-color);
  font-size: 0.9rem;
  white-space: break-spaces;

  &.with-bg {
    padding: 0 .2rem;
    border: .1rem solid rgba(0, 0, 0, .05);
    border-radius: .3rem;
    background-color: rgba(0, 0, 0, .05);
  }

  &.dark {
    color: var(--alg-secondary-color);
  }
}

.top-bar-overlay-panel {
    width: 450px;

    @media screen and (max-width: 599.98px) {
      width: 100% !important;
    }
}

.alg-dropdown-triangle {
  position: relative;
  margin-top: toRem(12);
  overflow: visible !important;

  &:before {
    content: '';
    position: absolute;
    top: toRem(-8);
    left: 50%;
    margin-left: toRem(-8);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 toRem(8) toRem(8) toRem(8);
    border-color: transparent transparent var(--alg-primary-color) transparent;
  }
}

.alg-divider {
  margin: 0;
  padding: 0;
  height: toRem(1);
  border: none;
  background: rgb(255,255,255);
  background: -moz-linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(209,215,230,1) 50%, rgba(255,255,255,0) 100%);
  background: -webkit-linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(209,215,230,1) 50%, rgba(255,255,255,0) 100%);
  background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(209,215,230,1) 50%, rgba(255,255,255,0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
}

.alg-search-result {
  em {
    padding: toRem(1);
    color: var(--alg-primary-color);
    background-color: var(--alg-search-result-highlight);
    font-style: normal;
    font-weight: 300;
  }
}
