@import 'src/assets/scss/functions';

.alg-switch {
  display: inline-block;
  height: toRem(24);

  .p-inputswitch {
    width: toRem(48);
    height: toRem(24);
  }

  .p-inputswitch.p-focus .p-inputswitch-slider {
    box-shadow: none;
  }

  .p-inputswitch-slider {
    background-color: var(--alg-switch-input-bg);
    border-radius: toRem(12);

    &:before {
      width: toRem(18);
      height: toRem(18);
      margin-top: toRem(calc(-1 * 18 / 2));
      background-color: var(--alg-white-color);
    }
  }

  .p-inputswitch-checked {
    .p-inputswitch-slider {
      background-color: var(--alg-primary-color);

      &:before {
        background-color: var(--alg-white-color);
        transform: translateX(toRem(22));
      }
    }
  }

  .p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
    background-color: var(--alg-primary-color);
  }
}
